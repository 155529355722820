<template>
  <div class="container-fluid mt-5">
    <div class="row my-3 justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="h3 d-none d-lg-block text-muted">Was brauche ich? Was bietet ihr an?</div>
        <div class="h3 d-block d-lg-none text-muted">Was bietet ihr an?</div>
        <div class="h1 d-none d-md-block company-color1">
          Unsere Führerscheinklassen im Überblick
          <br />
        </div>
        <div class="h1 d-block d-md-none company-color1">
          Führerschein&shy;klassen
          <br />
        </div>Egal ob du den Führerschein einfach nur haben willst, ihn für die Arbeit brauchst oder die Freiheit spüren willst...
        <br />Hier findest du alle Führerscheinklassen im Überblick.
        <br />
        <small class="text-muted">
          Hast du weitere Fragen?
          <br />Ruf uns an, schreib eine E-Mail oder komm vorbei! Wir nehmen uns Zeit für deine individuelle Beratung.
        </small>
      </div>
    </div>
    <div class="row my-3 justify-content-center p-1">
      <div class="col-12 col-lg-10 container-fluid">
        <div class="row my-3 justify-content-center">
          <div class="col-12 col-lg-10 shadow rounded px-3 py-2">
            <div class="container-fluid">
              <div class="row justify-content-center justify-content-lg-start">
                <h3 class="text-uppercase font-weight-bold">Auto</h3>
              </div>
              <div class="row">
                <div class="col-5 d-none d-lg-block">
                  <img width="100%" src="@/assets/pictures/lion_bartl/PSX_20190824_220848.jpg" />
                </div>
                <div class="col-10 col-lg-7 container-fluid">
                  <div
                    class="row justify-content-center justify-content-lg-start"
                  >Der Führerschein fürs Auto ist heutzutage wichtiger denn je. Egal ob du zur Arbeit, Uni oder deine Familie und Freunde besuchen willst. Mit einem Auto bist du mobil. Ein Auto gibt dir die freie Entscheidung, wie du deinen Tag gestalten willst.</div>
                  <div class="row justify-content-center justify-content-lg-start d-flex d-lg-none">
                    <img
                      width="100%"
                      height="auto"
                      src="@/assets/pictures/lion_bartl/PSX_20190824_220848.jpg"
                    />
                  </div>
                  <div id="keyfacts" class="row justify-content-center font-weight-bold small my-3">
                    <div
                      class="col col-lg-3 text-center badge badge-secondary my-1 mx-3"
                    >ab 17 Jahren (B17)</div>
                  </div>
                  <div class="row justify-content-center justify-content-lg-start">
                    <div class="col text-center my-2">
                      <a type="button" @click="clickAuto(0)" class="p-5">B17</a>
                    </div>
                    <div class="vdivider d-none d-lg-flex"></div>
                    <div class="col text-center my-2">
                      <a type="button" @click="clickAuto(1)" class="p-5">B</a>
                    </div>
                    <div class="vdivider d-none d-lg-flex"></div>
                    <div class="col text-center my-2">
                      <a type="button" @click="clickAuto(2)" class="p-5">BE</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <b-collapse v-model="collapse_auto">
                  <div v-if="auto_slide==0">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse B17 berechtigt zum Führen von Kraftfahrzeugen bis 3,5 t in Begleitung einer dazu berechtigten Person. Das kann zum Beispiel ein eingetragenes Familienmitglied sein.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz einer Fahrerlaubnis erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse AM ist in der Klasse B17 mit eingeschlossen. Ein Fahrzeug der Klasse AM kann wegen erreichtem Mindestalter ohne Begleitperson geführt werden.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>17 Jahre</td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Mit dem Erreichen des Mindestalters von 18 Jahren wird die Führerscheinklasse B ohne weitere Vorraussetzungen erteilt.</td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="auto_slide==1">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse B berechtigt zum Führen von Kraftfahrzeugen bis 3,5 t zulässigem Gesamtgewicht und max. 9 Sitzplätzen.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz einer Fahrerlaubnis erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klassen AM und L (kleine Traktoren) sind in der Klasse B mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          18 Jahre
                          <small>(in Verbindung mit B17 möglich)</small>
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="auto_slide==2">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse BE berechtigt zum Führen einer Fahrzeugkombination, die aus einem Zugfahrzeug der Klasse B und einem Anhänger bis 3,5 t zulässigem Gesamtgewicht besteht.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Klasse B erforderlich</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          18 Jahre
                          <small>(in Verbindung mit B17 möglich)</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Keine Theorieprüfung notwendig.</td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-3 justify-content-center">
          <div class="col-12 col-lg-10 shadow rounded px-3 py-2">
            <div class="container-fluid">
              <div class="row justify-content-center justify-content-lg-start">
                <h3 class="text-uppercase font-weight-bold">MOTORRAD</h3>
              </div>
              <div class="row">
                <div class="col-5 d-none d-lg-block">
                  <img
                    width="100%"
                    height="auto"
                    src="@/assets/pictures/pixabay/SplitShire/biker-407123_1920.jpg"
                  />
                </div>
                <div class="col-10 col-lg-7 container-fluid">
                  <div
                    class="row justify-content-center justify-content-lg-start"
                  >Es gibt viele Gründe Motorrad zu fahren. Für uns ist es Freiheit, Unabhängigkeit und ein einzigartiges Gefühl, welchem nichts so schnell nachsteht. Komm zu uns und verrate uns deinen!</div>
                  <div class="row justify-content-center justify-content-lg-start d-flex d-lg-none">
                    <img
                      width="100%"
                      src="@/assets/pictures/pixabay/SplitShire/biker-407123_1920.jpg"
                    />
                  </div>
                  <div id="keyfacts" class="row justify-content-center font-weight-bold small my-3">
                    <div
                      class="col col-lg-3 text-center badge badge-secondary my-1 mx-3"
                    >ab 16 Jahren(A1)</div>
                  </div>
                  <div class="row justify-content-center justify-content-lg-start">
                    <div class="col-5 col-lg text-center my-2">
                      <a type="button" @click="clickMotorrad(0)" class="p-5 active">A1</a>
                    </div>
                    <div class="vdivider d-none d-sm-flex"></div>
                    <div class="col-5 col-lg text-center my-2">
                      <a type="button" @click="clickMotorrad(1)" class="p-5">A2</a>
                    </div>
                    <div class="vdivider d-none d-lg-flex"></div>
                    <div class="col-12 col-lg text-center my-2">
                      <a type="button" @click="clickMotorrad(2)" class="p-5">A</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <b-collapse v-model="collapse_motorrad">
                  <div v-if="motorrad_slide==0">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse A1 berechtigt zum Führen von Krafträdern mit einem Hubraum von bis zu 125 ccm und einer Motorleistung von nicht mehr als 11 kW, bei denen das Verhältnis der Leistung zum Gewicht 0,1 kW/kg nicht übersteigen darf.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz einer Fahrerlaubnis erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse AM ist in der Klasse A1 mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>16 Jahre</td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Die Probezeit beginnt bereits mit der Klasse A1! Wer also bereits mit 16 die Klasse A1 erwirbt und in 2 Jahren nicht auffällig wird, ist mit 18 raus aus der Probezeit.
                          <br />
                          <br />Wer seinen Führerschein vor dem 01.04.1980 erworben hat, darf auch Kleinkrafträder der Klasse A1 fahren.
                          <br />Ab diesem Jahr kannst du, wenn du mind. 25 Jahre alt und 4 Jahre den Auto-Führerschein (Klasse B) besitzt diesen Führerschein ohne Prüfung fahren. Dazu brauchst du ledigleich 4 weitere Theoriestunden und 5 Praxisstunden zu absolvieren.
                          <br />
                          <small>Falls du dazu Fragen hast, sprich uns gerne an!</small>
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="motorrad_slide==1">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse A2 berechtigt zum Führen von Krafträdern mit einer Motorleistung von nicht mehr als 35 kW und einem Verhältnis der Leistung zum Gewicht von nicht mehr als 0,2 kW/kg, die nicht von einem Kraftrad mit einer Leistung von über 70 kW Motorleistung abgeleitet sind.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz einer Fahrerlaubnis erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klassen A1 und AM sind in der Klasse A2 mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>18 Jahre</td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Wer bereits seit 2 Jahren die Klasse A1 besitzt, muss lediglich eine praktische Prüfung ablegen (Stufenregelung).</td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="motorrad_slide==2">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse A berechtigt zum Führen von Motorrädern ohne Beschränkungen.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz einer Fahrerlaubnis erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klassen A2, A1 und AM sind in der Klasse A mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          24 Jahre
                          <br />
                          <small>(bzw. 20 Jahre bei Vorbesitz Klasse A2)</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Wer bereits seit 2 Jahren die Klasse A2 besitzt, muss lediglich eine praktische Prüfung ablegen (Stufenregelung).</td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-3 justify-content-center">
          <div class="col-12 col-lg-10 shadow rounded px-3 py-2">
            <div class="container-fluid">
              <div class="row justify-content-center justify-content-lg-start">
                <h3 class="text-uppercase font-weight-bold">BERUFSKRAFTFAHRER</h3>
              </div>
              <div class="row">
                <div class="col-5 d-none d-lg-block">
                  <img style width="100%" src="@/assets/pictures/unknown/k800_dscf0472_nav.jpg" />
                </div>
                <div class="col-10 col-lg-7 container-fluid">
                  <div class="row justify-content-center justify-content-lg-start">
                    Wenn du Berufskraftfahrer werden willst hast du mit den ganz großen Maschinen zutun. Wir bilden dich aus und machen dich fit.
                    <br />Übrigens: Wir vermitteln dich danach auch gerne an potentielle Arbeitgeber weiter!
                  </div>
                  <div class="row justify-content-center justify-content-lg-start d-flex d-lg-none">
                    <img
                      width="100%"
                      height="auto"
                      src="@/assets/pictures/unknown/k800_dscf0472_nav.jpg"
                    />
                  </div>
                  <div id="keyfacts" class="row justify-content-center font-weight-bold small my-3">
                    <div class="col-4 col-lg-3 text-center badge badge-secondary my-1 mx-3">
                      ab 21 Jahren
                      <span
                        class="d-none"
                      >bzw. 18 Jahre nur nach erfolgter Grundqualifikation nach BKrFQG oder nach Abschluss des Ausbildungsberufs "Berufskraftfahrer/in" oder für Fahrer von Einsatzfahrzeugen</span>
                    </div>
                  </div>
                  <div class="row justify-content-center justify-content-lg-start">
                    <div class="col-4 col-lg text-center my-2">
                      <a type="button" @click="clickLKW(0)" class="p-3 active">C1</a>
                    </div>
                    <div class="col-4 col-lg text-center my-2">
                      <a type="button" @click="clickLKW(1)" class="p-3">C1E</a>
                    </div>
                    <div class="col-4 col-lg text-center my-2">
                      <a type="button" @click="clickLKW(2)" class="p-3">C</a>
                    </div>
                    <div class="col-4 col-lg text-center my-2">
                      <a type="button" @click="clickLKW(3)" class="p-3">CE</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <b-collapse v-model="collapse_lkw">
                  <div v-if="lkw_slide==0">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse C1 berechtigt zum Führen von Kraftfahrzeugen mit mehr als 3,5 t bis maximal 7,5 t zulässigem Gesamtgewicht und maximal 9 Sitzplätzen. Ein Anhänger darf nur mit einem zulässigem Gesamtgewicht von maximal 750 kg mitgeführt werden.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Klasse B erforderlich</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          21 Jahre
                          <br />
                          <small>(nach Abschluss der beschleunigten Grundqualifikation (95') bzw. ab 18 Jahre nach Abschluss des Ausbildungsberufs „Berufskraftfahrer/-in")</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Weiteres</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Bei der Ersterteilung ist als Eignungsnachweis die Vorlage einer Gesundheitsuntersuchung und einer augenärztlichen Untersuchung erforderlich.
                          <br />Die Klasse C1 wird grundsätzlich nur für 5 Jahre erteilt. Für jeweils weitere 5 Jahre kann sie nach Vorlage einer positiven Eignungsuntersuchung (Gesundheits-Check und augenärztlichem Gutachten) verlängert werden.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="lkw_slide==1">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse C1E berechtigt zum Führen von Kraftfahrzeugen der Klasse C1, sowie in Kombination mit einem Anhänger oder Sattelanhänger mit einer zulässigen Gesamtmasse der Kombination von nicht mehr als 12 t.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Klasse C1 erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse BE ist in der Klasse C1E mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          21 Jahre
                          <br />
                          <small>(nach Abschluss der beschleunigten Grundqualifikation (95') bzw. ab 18 Jahre nach Abschluss des Ausbildungsberufs „Berufskraftfahrer/-in")</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Weiteres</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Bei der Ersterteilung ist als Eignungsnachweis die Vorlage einer Gesundheitsuntersuchung und einer augenärztlichen Untersuchung erforderlich.
                          <br />Die Klasse C1E wird grundsätzlich nur für 5 Jahre erteilt. Für jeweils weitere 5 Jahre kann sie nach Vorlage einer positiven Eignungsuntersuchung (Gesundheits-Check und augenärztlichem Gutachten) verlängert werden.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="lkw_slide==2">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse C berechtigt zum Führen von Kraftfahrzeugen mit mehr als 3,5 t zulässigem Gesamtgewicht und maximal 9 Sitzplätzen. Ein Anhänger darf nur mit einem zulässigem Gesamtgewicht von maximal 750 kg mitgeführt werden.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Klasse B erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse C1 ist in der Klasse C mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          21 Jahre
                          <br />
                          <small>(nach Abschluss der beschleunigten Grundqualifikation (95') bzw. ab 18 Jahre nach Abschluss des Ausbildungsberufs „Berufskraftfahrer/-in")</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Weiteres</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Bei der Ersterteilung ist als Eignungsnachweis die Vorlage einer Gesundheitsuntersuchung und einer augenärztlichen Untersuchung erforderlich.
                          <br />Die Klasse C wird grundsätzlich nur für 5 Jahre erteilt. Für jeweils weitere 5 Jahre kann sie nach Vorlage einer positiven Eignungsuntersuchung (Gesundheits-Check und augenärztlichem Gutachten) verlängert werden.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="lkw_slide==3">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse CE berechtigt zum Führen von Kraftfahrzeugen der Klasse C, sowie in Kombination mit einem Anhänger oder Sattelanhänger.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Klasse C erforderlich</td>
                      </tr>

                      <tr>
                        <th>Einschluss</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse BE, C1E und T sind in der Klasse CE mit eingeschlossen.</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          21 Jahre
                          <br />
                          <small>(nach Abschluss der beschleunigten Grundqualifikation (95') bzw. ab 18 Jahre nach Abschluss des Ausbildungsberufs „Berufskraftfahrer/-in")</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Weiteres</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Bei der Ersterteilung ist als Eignungsnachweis die Vorlage einer Gesundheitsuntersuchung und einer augenärztlichen Untersuchung erforderlich.
                          <br />Die Klasse CE wird grundsätzlich nur für 5 Jahre erteilt. Für jeweils weitere 5 Jahre kann sie nach Vorlage einer positiven Eignungsuntersuchung (Gesundheits-Check und augenärztlichem Gutachten) verlängert werden.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-3 justify-content-center">
          <div class="col-12 col-lg-10 shadow rounded px-3 py-2">
            <div class="container-fluid">
              <div class="row justify-content-center justify-content-lg-start">
                <h3 class="text-uppercase font-weight-bold">AM / Mofa</h3>
              </div>
              <div class="row">
                <div class="col-5 d-none d-lg-block">
                  <img width="100%" src="@/assets/pictures/pixabay/pictavio/vespa-4656784_1920.jpg" />
                </div>
                <div class="col-10 col-lg-7 container-fluid">
                  <div
                    class="row justify-content-center justify-content-lg-start my-2"
                  >Jeder fängt einmal klein an. Und dennoch, es kann den Schlüssel zur Freiheit bedeuten nicht mehr an Bus oder Bahn gebunden zu sein - seine eigenen Wege zu beschreiten. Das ist es worauf es ankommt.</div>
                  <div
                    class="row justify-content-center justify-content-lg-start d-flex d-lg-none my-2"
                  >
                    <img
                      width="100%"
                      height="auto"
                      src="@/assets/pictures/pixabay/pictavio/vespa-4656784_1920.jpg"
                    />
                  </div>
                  <div id="keyfacts" class="row justify-content-center font-weight-bold small my-3">
                    <div
                      class="col col-lg-3 text-center badge badge-secondary my-1 mx-3"
                    >ab 15 Jahren</div>
                    <div
                      class="col col-lg-3 text-center badge badge-secondary my-1 mx-3"
                    >für die Stadt</div>
                    <div
                      class="col col-xs-5 col-lg-3 text-center badge badge-secondary my-1 mx-3"
                    >kleiner Geldbeutel</div>
                  </div>
                  <div class="row justify-content-center justify-content-lg-start">
                    <div class="col text-center my-2">
                      <a
                        type="button"
                        @click="clickAMMofa(0)"
                        class="p-5 active"
                      >Mofa</a>
                    </div>
                    <div class="vdivider d-none d-sm-flex"></div>
                    <div class="col text-center my-2">
                      <a
                        type="button"
                        @click="clickAMMofa(1)"
                        class="p-5"
                      >AM</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <b-collapse v-model="collapse_ammofa">
                  <div v-if="ammofa_slide==0">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Mit der Mofa-Prüfbescheinigung darf man laut Definition einspurige Fahrräder mit Hilfsmotor – auch ohne Tretkurbeln – fahren, wenn ihre Bauart Gewähr dafür bietet, dass die Höchstgeschwindigkeit auf ebener Bahn nicht mehr als 25 km/h beträgt.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz erforderlich</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>15 Jahre</td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Einen Führerschein für ein Mofa gibt es nicht, jedoch darf mit jedem Führerschein - gleich welcher Klasse - ein Mofa gefahren werden!
                          <br />
                          <br />Wer vor dem 01.04.1980 15 Jahre alt war (also vor dem 01.04.1965 geboren ist), darf OHNE Prüfbescheinigung ein Mofa fahren.
                          <br />Für den Erwerb der Führerscheins muss nur eine theoretische Prüfung abgelegt werden.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                  <div v-else-if="ammofa_slide==1">
                    <table>
                      <tr>
                        <th>Berechtigung</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>Die Klasse AM berechtigt zum Führen von Kleinkrafträdern mit einer durch die Bauart bestimmten Höchstgeschwindigkeit von nicht mehr als 45 km/h und einer elektrischen Antriebsmaschine oder einem Verbrennungsmotor mit einem Hubraum von nicht mehr als 50 ccm oder einer maximalen Nenndauerleistung bis zu 4 kW im Falle von Elektromotoren.</td>
                      </tr>

                      <tr>
                        <th>Vorbesitz</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>kein Vorbesitz erforderlich</td>
                      </tr>

                      <tr>
                        <th>Mindestalter</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          15 Jahre
                          <small>(Sachsen stellt neben einigen anderen Bundesländern eine Ausnahme dar. Das ursprüngliche Mindestalter war 16 Jahre.)</small>
                        </td>
                      </tr>

                      <tr>
                        <th>Besonderheiten</th>
                      </tr>
                      <tr>
                        <td class="d-none d-lg-block"></td>
                        <td>
                          Dieser Führerschein ist bei Erwerb der Klasse B eingeschlossen OHNE zusätzliche Prüfung.
                          <br />
                          <br />Die Zeit in welcher du nur den AM Führerschein besitzt wird der Probezeit nicht angerechnet!
                          <br />
                          <br />Auch ein B17 Führerscheinbewerber darf mit 17 den Roller schon alleine fahren, da das Mindestalter für Klasse AM bei 16 Jahren liegt.
                        </td>
                      </tr>
                    </table>
                    <br />
                    <!-- <button type="button">Weitere Informationen</button> -->
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-5 justify-content-center">
          <div class="col-12 col-lg-10 px-3 py-2 row">
            <h3 class="col-12 text-uppercase font-weight-bold">Weitere Maßnahmen:</h3>
            <br />
            <div class="col-1 col-sm-2">
              <div class="w-100"></div>
            </div>
            <div class="col-11 col-sm-10">
              <ul>
                <li>Beschleunigte Grundqualifikation (95')</li>
                <li>Gabelstaplerausbildung</li>
                <li>Kranbedienerausbildung</li>
                <li>Ladekranausbildung</li>
                <li>Weiterbildung für Kraftfahrer (Modul 1-5)</li>
                <li>Ökotraining</li>
                <li>Ladungssicherung für Kraftfahrzeuge</li>
                <li>Erdbaumaschinenführer</li>
                <li>Perfektionstraining mit oder ohne ADR-Schein</li>
                <li>Sportbootführerschein</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row my-5 justify-content-center">
          <div class="col-12 col-lg-10 px-3 text-center">
            <hr class="pb-5" />
            <h3 class="font-weight-bold">Du hast Fragen?</h3>
            <span class>Wir beraten dich gerne!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide",
  data() {
    return {
      collapse_auto: false,
      collapse_motorrad: false,
      collapse_lkw: false,
      collapse_ammofa: false,
      auto_slide: 0,
      motorrad_slide: 0,
      lkw_slide: 0,
      ammofa_slide: 0
    };
  },
  methods: {
    openSingle(input) {
      if (input == "auto") this.collapse_auto = true;
      else this.collapse_auto = false;
      if (input == "motorrad") this.collapse_motorrad = true;
      else this.collapse_motorrad = false;
      if (input == "lkw") this.collapse_lkw = true;
      else this.collapse_lkw = false;
      if (input == "ammofa") this.collapse_ammofa = true;
      else this.collapse_ammofa = false;
    },
    clickAuto(slide) {
      this.auto_slide = slide;
      this.openSingle("auto");
    },
    clickMotorrad(slide) {
      this.motorrad_slide = slide;
      this.openSingle("motorrad");
    },
    clickLKW(slide) {
      this.lkw_slide = slide;
      this.openSingle("lkw");
    },
    clickAMMofa(slide) {
      this.ammofa_slide = slide;
      this.openSingle("ammofa");
    }
  }
};
</script>
